import { Menu } from './Menu';
import { RecoverPage } from './RecoverPage';
import { Context, Provider, Consumer } from './Context';
import { SET_SESSION } from './queries';

export const User = {
    Menu, 
    RecoverPage,
    Context,
    Consumer,
    Provider,
    SET_SESSION,
    routes: [{ path: "/recover/:token", component: RecoverPage }]
}