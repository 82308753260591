import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components'

import {
  NavDropdown,
  MenuItem,
  Glyphicon,
} from "react-bootstrap";
import { USER, LOGOUT, PROFILE, updateUser } from './queries';
import { ProfileModel } from './ProfileModel';

export class ActiveUser extends Component { 
  constructor(props) {
    super(props)
    this.state = {
      profile: false,
    }
  }

  toggle = (name) => () =>
    this.setState((state) => ({ [name]: !state[name] }))

  render () {
    const { user } = this.props;
    return (
      <Fragment>
        { user.hasRole('admin') &&
          <MenuItem key={"admin"} onClick={this.toggle('profile')}>
            <Glyphicon glyph="star" /> Admin
          </MenuItem>
        }
        { user.hasRole('mfa-verified') &&
          <MenuItem key={"verified"} onClick={this.toggle('profile')}>
            <Glyphicon glyph="check" /> Verified
          </MenuItem>
        }
        <NavDropdown key="user" title={<span><img key='photo' alt='avatar' src={`${user.photo}?s=18`}/> {user.display}</span>} id="basic-nav-dropdown">
          <MenuItem key={"profile"} onClick={this.toggle('profile')}>
            Profile
          </MenuItem>

          <Mutation mutation={LOGOUT} update={updateUser('phoenix')}>
            {(logout) => (
              <MenuItem onClick={logout}>Logout</MenuItem>
            )}
          </Mutation>
        </NavDropdown>
  
        <Mutation mutation={PROFILE} refetchQueries={[{ query: USER }]}>
          {(doProfile, profileState) => (
            <ProfileModel
              {...profileState}
              user={user}
              doProfile={doProfile}
              show={this.state.profile}
              onHide={this.toggle('profile')}
            />
          )}
        </Mutation>
      </Fragment>      
    );
  };
}
ActiveUser.propTypes = {
    user: PropTypes.shape({
      display: PropTypes.string.isRequired,
      identity: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      hasRole: PropTypes.func.isRequired,
    }).isRequired,

}  
export default ActiveUser;
