import React from 'react';
import PropTypes from 'prop-types';

export const Config = ({ config }) => (
  <div className="mercury-config">
    {config && config.map(c => (
      <p key={c.space} className="mercury-text">
        {c.notes && c.notes.map(n => (
          <span key={n} className="text-nowrap text-success">
            {`# ${n}`}
            <br />
          </span>
        ))}
        <span className="text-nowrap text-primary">
          {`@${c.space}`}
          {c.tags && c.tags.map(t => (
            <span key={t}>
              &nbsp;
              <span className="rounded bg-success text-light mercury-tag">
                {t}
              </span>
            </span>
          ))}
        </span>
        <br />
        {c.list && c.list.map(l => (
          <span key={`${l.name}-${l.seq}`} className="text-nowrap">
            {l.notes && l.notes.map(n => (
              <span key={n} className="text-nowrap text-success">
                {`# ${n}`}
                <br />
              </span>
            ))}
            <span className="text-info">{l.name}</span>
            &nbsp;
            {l.tags && l.tags.map(t => (
              <span key={t}>
                <span className="rounded bg-success text-light mercury-tag">
                  {t}
                </span>
                &nbsp;
              </span>
            ))}
            {l.values && l.values.map((v, i) => (
              <span key={v}>
                <span style={{ whiteSpace: 'pre' }}>{i > 0 && ''.padEnd(l.name.length + 1)}</span>
                :
                {v}
                <br />
              </span>
            ))}
          </span>
        ))}
        <br />
      </p>
    ))}
  </div>
);

const valueType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const spaceType = {
  space: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  list: PropTypes.arrayOf(valueType).isRequired,
};

Config.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape(spaceType),
  ).isRequired,
};

export default Config;
