import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
// import { withApollo, Query } from 'react-apollo'
import { useApolloClient } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';

import { setSession, USER } from './queries';

const defaultValue = {
    user: {
        identity: 'anon', 
        display: 'Guest',
        email: 'nobody@nowhere',
        hasGroup: () => false,
        hasRole: () => false,
    },
    active: false,
    loading: true,
    groups: [],
    roles: [],
    session: '',
};
export const { Provider, Consumer } = React.createContext(defaultValue);
const hasSome = self => (...args) => self && args.length > 0 && Array.isArray(args[0]) 
                              ? self.some(s => args[0].includes(s)) 
                              : self.some(s => args.includes(s))

export const Context = ({name = 'phoenix', children}) => {
  const client = useApolloClient();

  useEffect(() => setSession(client, name), [client, name]);

  return (
      <Query query={USER} pollInterval={60000}>
        {({data, loading, error}) => {
          const value = (loading || error) ? defaultValue : data.session;
          if (!value.user) value.user = defaultValue.user;

          const session = { 
            ...value, 
            loading, 
            user: {
                ...value.user,
                hasRole: hasSome(value.user.roles),
                hasGroup: hasSome(value.user.groups),
            }
          }

          return <Provider value={session} children={children}/>;  
        }}
      </Query>
    );
}
// context.propTypes = {
//     client: PropTypes.shape({
//         mutate: PropTypes.func.isRequired,
//     }).isRequired,
//     children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
// };
// export const Context = withApollo(context);
Context.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
