import React from 'react'
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Alert,
  HelpBlock,
} from "react-bootstrap";
import { ErrorBoundary } from '..';

export const LoginModel = ({ user, error, show, doLogin, onHide, onForgot }) => {
    let identity = '';
    let password = '';
    let authCode = '';
  
    const onSubmit = e => {
      e.preventDefault();
      doLogin({
        variables: {
          user: identity.value,
          pass: password.value,
          totpCode: authCode.value ? authCode.value : undefined,
        }
      })
    }
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>User Login</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
        <ErrorBoundary>
  
          {error&&(
            <Alert key="alert" bsStyle="warning">
              <p><strong>Error!</strong> {error.graphQLErrors.map(({message}) => <span key={message}>{message}</span>)}</p>
            </Alert>
          )}
  
          <Form horizontal onSubmit={onSubmit}>
            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={ControlLabel} sm={2}>
                Username
              </Col>
              <Col sm={10}>
                <input
                  className={"form-control"}
                  required
                  type="text"
                  name="username"
                  placeholder="Username"
                  autoFocus
                  ref={node => { identity = node; }}
                />
              </Col>
            </FormGroup>
  
            <FormGroup controlId="formHorizontalPassword">
              <Col componentClass={ControlLabel} sm={2}>
                Password
              </Col>
              <Col sm={10}>
                <input
                  className={"form-control"}
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
                  ref={node => { password = node; }}
                />
              </Col>
            </FormGroup>
  
            {user.hasRole('totp-active') &&
                <FormGroup controlId="code">
                  <Col componentClass={ControlLabel} sm={2}>
                    TOTP Code
                  </Col>
                  <Col sm={10}>
                    <input
                      className={"form-control"}
                      type="text"
                      id="code"
                      name="code"
                      placeholder="TOTP"
                      autoComplete="new-password"
                      required
                      ref={node => { authCode = node; }}
                    />
                    <HelpBlock>Enter your current one-time code to authorize the login.</HelpBlock>
                  </Col>
                </FormGroup>
              }

            <FormGroup>
              <Col xs={4} sm={3}>
               <Button bsStyle="link" onClick={onForgot}>I Forgot my password.</Button>
              </Col>

              <Col xsOffset={2} xs={3} smOffset={5} sm={2}>
                <Button type="submit" bsStyle="primary">Sign in</Button>
              </Col>
  
              <Col xs={3} sm={2}>
                <Button onClick={onHide}>Close</Button>
              </Col>
  
            </FormGroup>
          </Form>
          </ErrorBoundary>
        </Modal.Body>
      </Modal>
    )
  };
LoginModel.propTypes = {
    error: PropTypes.shape({
        graphQLErrors: PropTypes.array.isRequired,
    }),
    show: PropTypes.bool.isRequired,
    doLogin: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onForgot: PropTypes.func.isRequired,
};
  