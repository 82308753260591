import gql from 'graphql-tag';

export const EXAMPLE_SPACE = `# Example
# Space Comments
@space.name

# Attribute Comments
Attribute tag :Value for Attribute`;

export const QUERY = gql`
query config($space: String){
  config(space: $space){
    space tags notes list{ seq name values tags notes }
  }
}
`;

export const UPDATE = gql`
mutation write($payload: String!){
  write: writeConfigText(payload: $payload)
}
`;

export default { QUERY, UPDATE, EXAMPLE_SPACE };
