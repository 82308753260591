import React from 'react';
//import image from './phoenix.png';
import Logo from './Logo'
import './splash.css';

export const Splash = () => (
  <div className="Splash">
    <header>
    {/* <img src={image} className="logo" alt="logo" /> */}
      <Logo />
      <h1>Welcome to Phoenix Competition Managment</h1>
    </header>
    <p>
      <b>PhoenixCM</b> is the pinacle of synergizic excellence in motion. 
      <br />
      You probably don't belong here.
    </p>
  </div>
);
