import React from 'react';
import PropTypes from 'prop-types';

import './splitpane.css';

export function SplitPane({left, right}) {
    return (
      <div className="SplitPane">
        <div className="sidebar">
          {left}
        </div>
  
        <div className="main">
          {right}
        </div>
      </div>
    );
  }
  SplitPane.propTypes = {
    left: PropTypes.element,
    right: PropTypes.element
  }
  