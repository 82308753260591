import React, { Component, Fragment } from 'react'
import { Mutation } from '@apollo/react-components'

import {
  NavItem,
  Glyphicon,
} from 'react-bootstrap';

import { LOGIN, REGISTER, FORGOT, updateUser } from './queries';
import { LoginModel } from './LoginModel'
import { RegisterModel } from './RegisterModel';
import { ForgotModel } from './ForgotModel';

const user = updateUser('phoenix')

export class InactiveUser extends Component{
    constructor (props) {
      super(props);
  
      this.state = {
        login: false,
        register: false,
        recover: false,
      }
    }
  
    toggle = (name) => () =>
      this.setState((state) => ({ [name]: !state[name] }))
    
    render = () => (
      <Fragment>
        <NavItem eventKey='userRegisterButton' href='#' onClick={this.toggle('register')}>
          <Glyphicon glyph='user' /> Register
        </NavItem>
  
        <Mutation mutation={REGISTER} update={user}>
          {(doRegister, loginState) => (
            <RegisterModel
              {...loginState}
              doRegister={doRegister}
              show={this.state.register}
              onHide={this.toggle('register')}
            />
          )}
        </Mutation>
  
        <NavItem eventKey={'userLoginButton'} href='#' onClick={this.toggle('login')}>
          <Glyphicon glyph='user' /> Login
        </NavItem>
  
        <Mutation mutation={LOGIN} update={user}>
          {(doLogin, loginState) => (
            <LoginModel
              {...loginState}
              user={this.props.user}
              doLogin={doLogin}
              show={this.state.login}
              onHide={this.toggle('login')}
              onForgot={() => {this.toggle('recover')(); this.toggle('login')()}}
            />
          )}
        </Mutation>
  
        <Mutation mutation={FORGOT} update={user}>
          {(doForgot, state) => (
            <ForgotModel
              {...state}
              doForgot={doForgot}
              show={this.state.recover}
              onHide={this.toggle('recover')}
            />
          )}
        </Mutation>
      </Fragment>
    )
  }
  