import React from 'react'
import { Subscription, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { uuid } from 'components/utils'
import { GameClock } from './GameClock'

import './console.css'

const SUB = gql`
subscription{mqtt(topic: "phoenix/#"){ topic message }}
`;

const PUB = gql`
mutation pub($topic: String! $message: String!){mqttPublish(topic: $topic message: $message)}
`;

export class Console extends React.Component {
  state = {
    lines: []
  }

  pageEnd = ''

  componentDidUpdate () {
    this.scrollToBottom()
  }
  scrollToBottom = () => {
    this.pageEnd !== '' && this.pageEnd.scrollIntoView()
  }

  onData = ({subscriptionData: { data: { mqtt } }}) => {
    this.setState(({ lines }) => ({ lines: [ ...lines.slice(-10), mqtt ] }));
  }

  render = () => (
    <div>
      <div className="row">
        <div className="col-xs-3">
          <GameClock id={1} />
        </div>
        <div className="col-xs-3">
          <RemoteControl />
        </div>
      </div>  
      <div className='row'>
        <Subscription
          shouldResubscribe
          subscription={SUB}
          onSubscriptionData={this.onData}
        >
          {({loading}) => (
            <div>
              <dl className='phoenix-console'>{this.state.lines.map(
                ({topic, message}) => (
                  <React.Fragment>
                    <dt>{topic}</dt>
                    <dd>{message}</dd>
                  </React.Fragment>
                )
              )}</dl>
              <div ref={(ref) => { this.pageEnd = ref }} />
              {loading && <h1>Waiting for data...</h1>}
            </div>
          )}
        </Subscription>
      </div>
    </div>
  )
}
Console.SUB = SUB
Console.menu = [{ name: 'Console', link: '/console' }]
Console.routes = [
  { path: '/console', component: Console },
]

const UUID = uuid()
// function runOnce(fn, context) { 
//   var result;
//   return function() { 
//       if (fn) {
//           result = fn.apply(context || this, arguments);
//           fn = null;
//       }
//       return result;
//   };
// }


const RemoteControl = () => (
  <div>
    <p>REMOTE</p>
    <Mutation mutation={PUB}>
      {(fn) => {
            // runOnce(
            //   fn({
            //     variables:{
            //       topic: 'phoenix/telemetry',
            //       message: JSON.stringify({
            //        uuid: UUID,
            //        type: 'remote-control',
            //       }),
            //     }
            //   })
            // );
          
          return ''
      }}
    </Mutation>
    <Mutation mutation={PUB}>
      {(fn) => (
        <button 
          className="btn btnxs btn-success" 
          onClick={() => fn({
            variables:{
              topic: 'phoenix/action',
              message: JSON.stringify({
                uuid: UUID,
                type: 'remote-control',
                btn: 'lift',
              }),
            }
          })}
        >
          LIFT
        </button>
      )}
    </Mutation>

    <Mutation mutation={PUB}>
      {(fn) => (
        <button 
          className="btn btnxs btn-danger" 
          onClick={() => fn({ 
            variables:{
              topic: 'phoenix/action',
              message: JSON.stringify({
                uuid: UUID,
                type: 'remote-control',
                btn: 'no-lift',
              }),
            }
          })}
        >
          NO LIFT
        </button>
      )}
    </Mutation>
  </div>
)


export default Console