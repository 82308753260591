import React from 'react'

import { User } from '..';
import { ActiveUser } from './ActiveUser';
import { InactiveUser } from './InactiveUser';

export const Menu = () => (
  <User.Consumer>
    {(session) => {
      const { loading, active } = session;
      if (loading) return null;
      if (!active) return <InactiveUser {...session} />;
      return <ActiveUser {...session} />;
    }}
  </User.Consumer>
);
