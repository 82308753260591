import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react'

import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  ControlLabel,
  Alert,
  HelpBlock,
  Image,
  Panel,
} from "react-bootstrap";
import { ErrorBoundary } from '..';

export const ProfileModel = ({ user, error, show, doProfile, onHide}) => {
    let password = '';
    let authCode = '';

    let email = '';
    let display = '';
    let newPassword = '';
    let totpSeed = null;
    let totpCode = null;

    const onSubmit = e => {
      e.preventDefault();
      doProfile({
        variables: {
          identity: user.identity,
          password: password.value,
          authCode: authCode.value,

          display: display.value,
          email: email.value,
          newPassword: newPassword.value,
          totpSeed: totpSeed === null ? undefined : totpSeed,
          totpCode: totpCode === null ? undefined : totpCode,
        }
      }).then(onHide)
    }
    const clearTOTP = e => {
      e.preventDefault();
      console.log({
        variables: {
          identity: user.identity,
          password: password.value,
          authCode: authCode.value,

          display: display.value,
          email: email.value,
          newPassword: newPassword.value,
          totpSeed: totpSeed === null ? undefined : totpSeed,
          totpCode: totpCode === null ? undefined : totpCode,
        }
      });
      totpCode = '';
      totpSeed = '';
      console.log({
        variables: {
          identity: user.identity,
          password: password.value,
          authCode: authCode.value,

          display: display.value,
          email: email.value,
          newPassword: newPassword.value,
          totpSeed: totpSeed === null ? undefined : totpSeed,
          totpCode: totpCode === null ? undefined : totpCode,
        }
      });
    }
    const setUser = () => {
      display.value = user.display
      email.value = user.email
    };
    const setTOTP = (seed, code) => {
      totpSeed = seed
      totpCode = code
    }

    if (!show) return null;  
  
    return (
      <Modal show={show} onHide={onHide} onEnter={setUser}>
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <ErrorBoundary>
            <Form horizontal onSubmit={onSubmit}>
              {error&&(
                <Alert bsStyle="warning">
                    <p>
                      <strong>Error!</strong> 
                      {error.message && <span key={"message"}>{error.message}</span>}
                      {error.graphQLErrors && error.graphQLErrors.map(({message}) => <span key={message}> {message}</span>)}
                      {error.NetworkError && error.NetworkError.map(({message}) => <span key={message}> {message}</span>)}
                    </p>
                </Alert>
              )}

              <Panel>
                <Row>
                  <Col xs={2}>
                    <Image rounded alt="Profile Photo" src={user.photo} />
                  </Col>
              
                  <Col xs={10}>
                    <h3>{user.display}<br /><small>{user.email}</small></h3>
                  </Col>
                </Row>
              </Panel>
              
              <hr />

              <FormGroup controlId="display">
                <Col componentClass={ControlLabel} sm={3}>
                  Display Name
                </Col>
                <Col sm={6}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="display"
                    name="display"
                    placeholder="Display Name"
                    ref={node => { display = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup controlId="email">
                <Col componentClass={ControlLabel} sm={3}>
                  Email
                </Col>
                <Col sm={9}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    autoComplete="false"
                    ref={node => { email = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup controlId="password">
                <Col componentClass={ControlLabel} sm={3}>
                  New Password
                </Col>
                <Col sm={9}>
                  <input
                    className={"form-control"}
                    type="password"
                    id="new-password"
                    name="new-password"
                    placeholder="Password"
                    autoComplete="new-password"
                    ref={node => { newPassword = node; }}
                  />
                  <HelpBlock>Leave blank to keep the same password.</HelpBlock>
                </Col>
              </FormGroup>
              
              {user.hasRole('totp-active')
                ? <HasQRCode clearTOTP={clearTOTP}/>
                : <QRCodeComponent user={user} setTOTP={setTOTP}/>
              }

              <hr />
              <b>Authorize Changes</b>
              <FormGroup controlId="password">
                <Col componentClass={ControlLabel} sm={3}>
                  Password
                </Col>
                <Col sm={9}>
                  <input
                    className={"form-control"}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    required
                    ref={node => { password = node; }}
                  />
                  <HelpBlock>Enter your current password to authorize the changes above.</HelpBlock>
                </Col>
              </FormGroup>

              {user.hasRole('totp-active') &&
                <FormGroup controlId="code">
                  <Col componentClass={ControlLabel} sm={3}>
                    TOTP Code
                  </Col>
                  <Col sm={9}>
                    <input
                      className={"form-control"}
                      type="text"
                      id="code"
                      name="code"
                      placeholder="TOTP"
                      autoComplete="new-password"
                      required
                      ref={node => { authCode = node; }}
                    />
                    <HelpBlock>Enter your current one-time code to authorize the changes above.</HelpBlock>
                  </Col>
                </FormGroup>
              }

              <FormGroup>
                <Col xsOffset={6} xs={3} smOffset={8} sm={2}>
                  <Button type="submit" bsStyle="primary">Update</Button>
                </Col>
  
                <Col xs={3} sm={2}>
                  <Button onClick={onHide}>Close</Button>
                </Col>
              </FormGroup>
            </Form>
          </ErrorBoundary>
  
        </Modal.Body>
      </Modal>
  
    )
  }
ProfileModel.propTypes = {
    doProfile: PropTypes.func.isRequired,
    error: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}  
  
class HasQRCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clear: false
    }
  }
  render() {
    const clearTOTP = (e) => {
      e.preventDefault(); 
      this.props.clearTOTP(e);
      this.setState({ clear: true })
    }
    const { clear } = this.state;
    return (
      <FormGroup controlId="totp-seed">
        <Col componentClass={ControlLabel} sm={3}>
          TOTP
        </Col>
        <Col sm={9}>
          {clear 
          ? <span className="alert alert-success"> Removing TOTP on update </span>
          : <button className="btn btn-default" onClick={clearTOTP}> Remove TOTP </button>}
        </Col>
      </FormGroup>    
    )  
  }
}
HasQRCode.propTypes = {
  clearTOTP: PropTypes.func.isRequired,
}

class QRCodeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      seed: '',
      code: '',
    }
  }

  onChange = e => {
    const {target} = e;
    const {name, value} = target;
    this.setState((state) => ({ ...state, [name]: value }), () => this.props.setTOTP(this.state.seed, this.state.code))
  }

  onShow = () => this.setState(({ show }) => ({ show: !show, seed: makeid() }))
  
  getURI = () => `otpauth://totp/PhoenixCM:${this.props.user.identity}?secret=${this.state.seed}&issuer=PhoenixCM`

  render() {
    const { show, seed, code } = this.state

    return show 
      ? ( 
      <Fragment>
        <FormGroup controlId="totp-seed">
          <Col componentClass={ControlLabel} sm={3}>
            TOTP
          </Col>
          <Col sm={9}>
            <QRCode value={this.getURI()}/>
            <input
              className={"form-control"}
              type="text"
              id="totp-seed"
              name="seed"
              readOnly
              placeholder="TOTP"
              autoComplete="new-password"
              value={seed}
            />
          </Col>
        </FormGroup>
  
        <FormGroup controlId="totp-code">
          <Col componentClass={ControlLabel} sm={3}>
            TOTP Code
          </Col>
          <Col sm={9}>
            <input
              className={"form-control"}
              type="text"
              id="totp-code"
              name="code"
              placeholder="TOTP"
              autoComplete="new-password"
              value={code}
              required
              onChange={this.onChange}
            />
            <HelpBlock>Enter a code generated from the QR Code.</HelpBlock>
          </Col>
          </FormGroup>
      </Fragment> 
    ) : (
      <FormGroup controlId="totp-seed">
        <Col componentClass={ControlLabel} sm={3}>
          TOTP
        </Col>
        <Col sm={9}>
          <button onClick={this.onShow}>Add TOTP protection</button>
          <HelpBlock>Click here to enroll a One-Time code generator.</HelpBlock>
        </Col>
      </FormGroup>
    )
  }
}

function makeid() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";

  for (var i = 0; i < 16; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
