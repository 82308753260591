import React from 'react'
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Alert,
} from "react-bootstrap";
import { ErrorBoundary } from '..';
import { remoteService } from '../utils';

const req = remoteService();

export const ForgotModel = ({ doForgot, error, data, show, onHide }) => {
    let captcha = '';
    let code = '';
    let ident = '';
    
    const getCaptcha = async () => {
      req('/v1/captcha/json').get()
        .then(res => (res.ok) ? res.json() : { data: {captcha: 'error' } })
        .then(({captcha: c}) => {
          captcha.src = `/v1/captcha/${c}.png`;
          captcha.id = c
        });
    }
  
    const onSubmit = async (e) => {
      e.preventDefault();
      doForgot({
        variables: {
          identity: ident.value,
          captcha: captcha.id,
          code: code.value,
        }
      })
    }
  
    return (
      <Modal show={show} onHide={onHide} onEnter={getCaptcha}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Account</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <ErrorBoundary>
            <Form horizontal onSubmit={onSubmit}>
              {error&&(
                <Alert bsStyle="warning">
                  <p>
                    <strong>Error!</strong> 
                    {error.message && !error.graphQLErrors && <span key={"message"}>{error.message}</span>}
                    {error.graphQLErrors && error.graphQLErrors.map(({message}) => <span key={message}> {message}</span>)}
                  </p>
                </Alert>
              )}
  
              {data&&(
                <Alert bsStyle="success">
                  <p>
                    <strong>Success! </strong> 
                    {data && <span key={"success"}>{data.message}</span>}
                  </p>
                </Alert>
              )}
  
              <FormGroup controlId="formHorizontalUsername">
                <Col componentClass={ControlLabel} sm={2}>
                  Username
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    name="ident"
                    placeholder="Username"
                    autoFocus
                    ref={node => { ident = node; }}
                  />
                </Col>
              </FormGroup>
  
              <div style={{ textAlign: 'center' }}>
                <img 
                  className='img-rounded' 
                  alt="Captcha"
                  ref={node => { captcha = node; }}
                 />
              </div>
  
              <FormGroup controlId="formHorizontalCapcha">
                <Col componentClass={ControlLabel} sm={2}>
                  Capcha
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    name="code"
                    placeholder="Capcha Code"
                    ref={node => { code = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup>
                <Col xsOffset={6} xs={3} smOffset={8} sm={2}>
                  <Button type="submit" bsStyle="primary">Recover</Button>
                </Col>
  
                <Col xs={3} sm={2}>
                  <Button onClick={onHide}>Close</Button>
                </Col>
              </FormGroup>
            </Form>
          </ErrorBoundary>
        </Modal.Body>
      </Modal>
    )
  };
ForgotModel.propTypes = {
  doForgot: PropTypes.func.isRequired,
  error: PropTypes.object,
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}  
  