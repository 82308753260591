import React from 'react'
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components'
import { RecoverModel } from './RecoverModel'
import { RECOVER, updateUser } from './queries';

const user = updateUser('phoenix');

export const RecoverPage = ({ match: { params: { token } }, history: { replace } }) => (
    <Mutation mutation={RECOVER} update={user}>
      {(doRecover, loginState) => (
        <RecoverModel
          {...loginState}
          token={token}
          doRecover={doRecover}
          show={true}
          onHide={()=>replace({ pathname: "/" })}
        />
      )}
    </Mutation>
  );
  RecoverPage.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired,
      })
    }),
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    })
  }
  
  export default RecoverPage;
