import { Athletes } from './athletes'
import { Events } from './events'
import { Console } from './console'
import { Mercury } from './mercury'

export const routes = [
    ...Athletes.routes,
    ...Events.routes,
    ...Console.routes,
    ...Mercury.routes,
]

export const menu = [
    ...Athletes.menu,
    ...Events.menu,
    ...Console.menu,
    ...Mercury.menu,
]