import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import to from 'await-to-js';

import { QUERY, UPDATE, EXAMPLE_SPACE } from './consts';
import { Config } from './Config.js';
import { WebAuthn } from './WebAuthn';

import './mercury.css';

export const Mercury = () => {
  const { query, mutate } = useApolloClient();

  const [space, setSpace] = useState('webauthn.*');
  const [config, setConfig] = useState([]);
  const [edit, setEdit] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const load = async () => {
    setLoading(true);
    const [err, { data }] = await to(query({ query: QUERY, variables: { space }, fetchPolicy: 'network-only' }));
    setLoading(false);
    if (err) return;
    setConfig(data.config);

    return false;
  };

  const onClick = async (e) => {
    e.preventDefault();
    load();
  };

  const save = async () => {
    setResult('');
    setSaving(true);
    const [err, { data }] = await to(mutate({ mutation: UPDATE, variables: { payload: edit } }));
    setSaving(false);
    if (err) return;
    setResult(data.write);

    return false;
  };

  const onSave = async (e) => {
    e.preventDefault();
    save();
  };

  // Load an initial dataset to be displayed. (run once)
  // eslint-disable-next-line
  useEffect(() => { load(); }, []);

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12">
        <div className="input-group">
          <input className="form-control" name="space" value={space} onChange={e => setSpace(e.target.value)} />
          <div className="input-group-btn">
            <button
              className="btn btn-primary"
              type="button"
              onClick={onClick}
              disabled={loading}
            >
              {loading ? 'loading...' : 'LOAD!'}
            </button>
          </div>
        </div>
        <br />
        {config && <Config config={config} />}
        <br />
        <WebAuthn />
      </div>
      <div className="col-md-6 col-xs-12">
        <div className="mercury-editor col-md-6 col-xs-12">
          <textarea className="form-control mercury-text" rows={20} value={edit} placeholder={EXAMPLE_SPACE} onChange={e => setEdit(e.target.value)} />
          <button
            type="button"
            className="btn btn-outline-primary btn-block"
            onClick={onSave}
          >
            {saving ? 'Saving...' : 'SAVE!'}
          </button>
          <pre>{result}</pre>
        </div>
      </div>
    </div>
  );
};

// export const Mercury = withApollo(MercuryFn);

Mercury.QUERY = QUERY;
Mercury.UPDATE = UPDATE;

Mercury.menu = [{ name: 'Mercury', link: '/mercury' }]
Mercury.routes = [
  { path: '/mercury', component: Mercury },
]

export default Mercury;
