import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import to from 'await-to-js';
import gql from 'graphql-tag';
import { User } from 'components'

export const registerBegin = gql`
mutation registerBegin{
  txt: webauthnRegisterBegin
}
`;

export const registerFinish = gql`
mutation registerFinish($payload: String!){
  txt: webauthnRegisterFinish(payload: $payload)
}
`;

export const loginBegin = gql`
mutation loginBegin{
  txt: webauthnLoginBegin
}
`;

export const loginFinish = gql`
mutation loginFinish($payload: String!){
  txt: webauthnLoginFinish(payload: $payload)
}
`;


const buttonStyle = {
  padding: 10,
  margin: 5
};

// easy way to go from string to ByteArray
const enc = new TextEncoder();

// another function to go from string to ByteArray, but we first encode the
// string as base64 - note the use of the atob() function
//
// eslint-disable-next-line no-useless-escape
export const strToBin = str => Uint8Array.from(atob(str.replace(/[_\-]/g, c => c === '-' ? '+' : c === '-' ? '/' : '')), c => c.charCodeAt(0));

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function
export const binToStr = bin => btoa(new Uint8Array(bin).reduce(
  (s, byte) => s + String.fromCharCode(byte), ''
// eslint-disable-next-line no-useless-escape
)).replace(/[\/\+=]/g,c => c === '+' ? '-' : c === '/' ? '_' : '');

export const jsonToObj = txt => JSON.parse(atob(txt));
const objToJson = o => btoa(JSON.stringify(o));

export const WebAuthn = () => {
  const { mutate } = useApolloClient();

  const createCreds = async (e) => {
    e.preventDefault();

    const [err, { data }] = await to(mutate({ mutation: registerBegin }));
    if (err) return;

    const { publicKey } = jsonToObj(data.txt);
    publicKey.challenge = strToBin(publicKey.challenge);
    publicKey.user.id = enc.encode(publicKey.user.id);

    const res = await navigator.credentials.create({ publicKey: publicKey })

    const payload = {
      id: res.id, 
      rawId: binToStr(res.rawId), 
      type: res.type, 
      response: {
        attestationObject: binToStr(res.response.attestationObject), 
        clientDataJSON: binToStr(res.response.clientDataJSON),
      },
    }

    const [err1, data1] = await to(mutate({ mutation: registerFinish, variables: { payload: objToJson(payload) } }));
    if (err1) return;
    console.log(data1);
  };

  const validateCreds = async (e) => {
    e.preventDefault();

    const [err, { data }] = await to(mutate({ mutation: loginBegin }));
    if (err) return;

    const { publicKey } = jsonToObj(data.txt);
    publicKey.challenge = strToBin(publicKey.challenge);
    if (publicKey.allowCredentials) {
      for (let i = 0; i < publicKey.allowCredentials.length; i++) {
        console.log(publicKey.allowCredentials[i].id);
        publicKey.allowCredentials[i].id = strToBin(publicKey.allowCredentials[i].id);
      }
    }

    console.log(publicKey);

    const credential = await navigator.credentials.get({ publicKey });

    console.log(credential);

    const payload = {
      id: credential.id, 
      rawId: binToStr(credential.rawId), 
      type: credential.type, 
      response: {
        authenticatorData: binToStr(credential.response.authenticatorData), 
        clientDataJSON: binToStr(credential.response.clientDataJSON),
        signature: binToStr(credential.response.signature),
				userHandle: binToStr(credential.response.userHandle),
      },
    }

    const [err1, data1] = await to(mutate({ mutation: loginFinish, variables: { payload: objToJson(payload) } }));
    if (err1) return;
    console.log(data1);

    const [err2, data2] = await to(mutate({ mutation: User.SET_SESSION, variables: { session: data1.data.txt } }));
    if (err2) return;
    console.log(data2);

    sessionStorage.setItem('phoenix', data1.data.txt);

  }

  return (
    <div className="App">
      <header className="App-header">
        <p> WebAuthn API Demo</p>
        
        <button 
          style={buttonStyle} 
          onClick={createCreds}
        >
          Register Creds
        </button>

        <button 
          style={buttonStyle} 
          onClick={validateCreds}
        >
          Validate Creds
        </button>
      </header>
    </div>
  );
};

export default WebAuthn;
