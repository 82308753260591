import React from 'react';
import './logo.css';

export const Logo = () => (
    <div className="phoenix-logo">
        <p><i className="rd">    _,     ,_    </i><i className="bl"> __________.__                         .__        </i></p>
        <p><i className="rd">  .'/  ,_   \'.  </i><i className="bl">\______    \  |__   ____   ____   ____ |__|__  ___</i></p>
        <p><i className="rd"> |  \__( &gt;__/  |</i><i className="bl">   |     ___/  |  \ /  _ \_/ __ \ /    \|  \  \/  /</i></p>
        <p><i className="rd"> \             / </i><i className="bl">  |    |   |   Y  (  &lt;_&gt; )  ___/|   |  \  |&gt;    &lt; </i></p>
        <p><i className="rd">  '-..__ __..-'  </i><i className="bl">  |____|   |___|  /\__<b>Competition Managment (tm)</b> \</i></p>
        <p><i className="rd">       /_\.      </i><i className="bl">                \/            \/     \/   \/    \/</i></p>
    </div>
);

export default Logo;