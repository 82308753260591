import React from 'react'
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Alert,
} from "react-bootstrap";
import { ErrorBoundary } from '..';
import { remoteService } from '../utils';

const req = remoteService();

export const RegisterModel = ({error, show, doRegister, onHide}) => {
    let ident = '';
    let password = '';
    let email = '';
    let display = '';
    let captcha = '';
    let code = '';
  
    const onSubmit = e => {
      e.preventDefault();
      doRegister({
        variables: {
          identity: ident.value,
          display: display.value,
          email: email.value,
          password: password.value,
          captcha: captcha.id,
          code: code.value,
        }
      })
    }
    const getCaptcha = async () => {
      req('/v1/captcha/json').get()
        .then(res => (res.ok) ? res.json() : { data: {captcha: 'error' } })
        .then(({captcha: c}) => {
          captcha.src = `/v1/captcha/${c}.png`;
          captcha.id = c;
        });
    }
    if (!show) return null;
  
  
    return (
      <Modal show={show} onHide={onHide} onEnter={getCaptcha}>
        <Modal.Header closeButton>
          <Modal.Title>User Register</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <ErrorBoundary>
            <Form horizontal onSubmit={onSubmit}>
            {error&&(
              <Alert bsStyle="warning">
                  <p>
                    <strong>Error!</strong> 
                    {error.message && <span key={"message"}>{error.message}</span>}
                    {error.graphQLErrors && error.graphQLErrors.map(({message}) => <span key={message}> {message}</span>)}
                    {error.NetworkError && error.NetworkError.map(({message}) => <span key={message}> {message}</span>)}
                  </p>
              </Alert>
            )}
  
              <FormGroup controlId="username">
                <Col componentClass={ControlLabel} sm={2}>
                  Username
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    autoFocus
                    ref={node => { ident = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup controlId="display">
                <Col componentClass={ControlLabel} sm={2}>
                  Display
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="display"
                    name="display"
                    placeholder="display"
                    ref={node => { display = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup controlId="email">
                <Col componentClass={ControlLabel} sm={2}>
                  Email
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="email"
                    name="email"
                    placeholder="email"
                    ref={node => { email = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup controlId="password">
                <Col componentClass={ControlLabel} sm={2}>
                  Password
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    ref={node => { password = node; }}
                  />
                </Col>
              </FormGroup>
  
              <div style={{ textAlign: 'center' }}>
                <img 
                  className='img-rounded' 
                  alt="Captcha"
                  ref={node => { captcha = node; }}
                 />
              </div>
  
              <FormGroup controlId="code">
                <Col componentClass={ControlLabel} sm={2}>
                  Captcha
                </Col>
                <Col sm={10}>
                  <input
                    className={"form-control"}
                    required
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Captcha Code"
                    ref={node => { code = node; }}
                  />
                </Col>
              </FormGroup>
  
              <FormGroup>
                <Col xsOffset={6} xs={3} smOffset={8} sm={2}>
                  <Button type="submit" bsStyle="primary">Register</Button>
                </Col>
  
                <Col xs={3} sm={2}>
                  <Button onClick={onHide}>Close</Button>
                </Col>
              </FormGroup>
            </Form>
          </ErrorBoundary>
  
        </Modal.Body>
      </Modal>
  
    )
  }
RegisterModel.propTypes = {
    doRegister: PropTypes.func.isRequired,
    error: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}  
  