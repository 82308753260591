import React from 'react'
import PropTypes from 'prop-types';

import { Nav, Navbar, NavItem, MenuItem, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import { Footer, User } from '.';

import 'bootstrap/dist/css/bootstrap.css';
import './framework.css';
import { ErrorBoundary } from './ErrorBoundary';


export const Framework = ({ overlay, children, ...rest }) => {
  return (
    <React.Fragment>
      <div>
        <header><ErrorBoundary><HeadNav {...rest}/></ErrorBoundary></header>
        <section className="container-fluid">
          <ErrorBoundary>
          <User.Consumer>
            { ({active}) => active ? children : overlay }
          </User.Consumer>
          </ErrorBoundary>
        </section>
        <Footer/>
      </div>
    </React.Fragment>
  )
}
Framework.propTypes = {
  overlay: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.elem)]).isRequired,
  children: PropTypes.oneOfType([PropTypes.array,PropTypes.element]),
  rest: PropTypes.oneOfType([PropTypes.array,PropTypes.element]),
}

function HeadNav({nav, user}) {
  const {title, menu} = nav;
  const Element = user;

  return (
    <Navbar inverse collapseOnSelect fixedTop fluid>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">{title}</Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>

      <Navbar.Collapse>
        <HeadMenu menu={menu}/>
        <Nav pullRight>
          <Element/>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
HeadNav.propTypes = {
  nav: PropTypes.shape({
    title: PropTypes.string.isRequired,
    menu: PropTypes.arrayOf(
      PropTypes.shape({

      })
    ).isRequired,
  }),
  user: PropTypes.func,
}

function HeadMenu({menu}) {
  if (!!!menu) return null;

  return (
    <Nav>
      <User.Consumer>
          { ({active}) => active && menu.map((item, i) => renderMain(item, i)) }
      </User.Consumer>
    </Nav>
  );
}
HeadMenu.propTypes = {
  menu: PropTypes.array
}

function renderDropdown(item, key) {
  if (!!item.divider) return (
    <MenuItem key={key} divider/>
  );
    
  if (!!item.link) return (
    <LinkContainer key={key} to={item.link}>
      <MenuItem>{item.name}</MenuItem>
    </LinkContainer>
  );

  if (!!item.url) return (
    <MenuItem key={key} href={item.url}>{item.name}</MenuItem>
  );
}

function renderMain(item, key) {
  if (!!item.divider) return (
    <MenuItem key={key} divider />
  );


  if (!!item.link) return (
    <NavItem key={key} href={item.link}>{item.name}</NavItem>
  );

  // if (!!item.link) return (
  //   <LinkContainer key={key} to={item.link}>
  //     <NavItem title={item.name}>{item.name}</NavItem>
  //   </LinkContainer>
  // );

  if (!!item.items) return (
    <NavDropdown key={key} title={item.name} id="basic-nav-dropdown">
      {item.items.map((mi, i) => renderDropdown(mi, i))}
    </NavDropdown>
  );

  if (!!item.url) return (
    <NavItem key={key} href={item.url}>{item.name}</NavItem>
  );
}
