
import React from 'react'

import { Query } from 'react-apollo'
import gql from 'graphql-tag';

import { Link } from 'react-router-dom'

import { SplitPane } from 'components';
import './athletes.css';


const LIST = gql`{
  athlete { id givenName familyName birthDate gender memberCode nation }
}`

export const List = ({ activeID, athlete }) => (
    <div className='athlete-list list-group'>
        {athlete.map(({ id, givenName, familyName, birthDate, gender, nation }) => (
            <Link key={id} className={`list-group-item ${activeID === id ? 'active' : ''}`} to={`/athletes/${id}`}>
                <p><strong>{familyName}</strong>, {givenName} <strong>({nation})</strong></p>
                <small>DOB: {new Date(birthDate).toLocaleDateString()} GEN: {gender}</small>
            </Link>
        ))}  
    </div> 
);

export const Athlete = ({ activeID, athlete }) => (
    <pre>{activeID} {JSON.stringify(athlete.find(({id}) => id === activeID), null, 2)}</pre>
);

export const Athletes = ({ match: { params } }) => (
    <Query query={LIST}>
        {({ loading, error, data }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error :(</p>;
            
            const activeID = parseInt(params.id, 10);
            let athlete = [ ...data.athlete ];
            athlete.sort((a,b) => `${a.familyName} ${a.givenName}`.localeCompare(`${b.familyName} ${b.givenName}`));

            return (
                <SplitPane left={<List activeID={activeID} athlete={athlete}/>} right={<Athlete activeID={activeID} athlete={athlete}/>} />
            )
        }}
    </Query>
);
Athletes.menu = [
    {name: 'Athletes', link: '/athletes'},
]
Athletes.routes = [
    { path: '/athletes/:id', component: Athletes },
    { path: '/athletes', component: Athletes },
];

export default Athletes;