import React from 'react'
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Alert,
} from "react-bootstrap";
import { ErrorBoundary } from '..';


export const RecoverModel = ({ token, doRecover, error, data, show, onHide }) => {
    let ident = '';
    let password = '';
  
    if (!show) return null;
  
    if (!!data) {
      onHide()
    }
  
    const onSubmit = e => {
      e.preventDefault();
      doRecover({
        variables: {
          token,
          identity: ident.value,
          password: password.value,
        }
      })
    }
  
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Account</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
        <ErrorBoundary>
  
          <Form horizontal onSubmit={onSubmit}>
          {error&&(
            <Alert bsStyle="warning">
              <p>
                <strong>Error!</strong> 
                {error.message && !error.graphQLErrors && <span key={"message"}>{error.message}</span>}
                {error.graphQLErrors && error.graphQLErrors.map(({message}) => <span key={message}> {message}</span>)}
              </p>
            </Alert>
          )}
  
            <FormGroup controlId="formHorizontalUsername">
              <Col componentClass={ControlLabel} sm={2}>
                Username
              </Col>
              <Col sm={10}>
                <input
                  className={"form-control"}
                  required
                  type="text"
                  name="username"
                  placeholder="Username"
                  autoFocus
                  ref={node => { ident = node; }}
                />
              </Col>
            </FormGroup>
  
            <FormGroup controlId="formHorizontalPassword">
              <Col componentClass={ControlLabel} sm={2}>
                Password
              </Col>
              <Col sm={10}>
                <input
                  className={"form-control"}
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
                  ref={node => { password = node; }}
                />
              </Col>
            </FormGroup>
  
            <FormGroup>
              <Col xsOffset={6} xs={3} smOffset={8} sm={2}>
                <Button type="submit" bsStyle="primary">Recover</Button>
              </Col>
  
              <Col xs={3} sm={2}>
                <Button onClick={onHide}>Close</Button>
              </Col>
            </FormGroup>
          </Form>
          </ErrorBoundary>
  
        </Modal.Body>
      </Modal>
  
    )
  }
RecoverModel.propTypes = {
    token: PropTypes.string.isRequired,
    doRecover: PropTypes.func.isRequired,
    error: PropTypes.object,
    data: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}  

export default RecoverModel;
