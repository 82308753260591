import gql from 'graphql-tag';

export const USER_FIELDS = gql `fragment Me on Session {active session user{ display identity email photo groups roles }}`
export const USER = gql`query { session: me{ ...Me } } ${USER_FIELDS}`

export const SET_SESSION = gql`
mutation ($session: String!) {
    session: setSession(session: $session){ ...Me }
}
${USER_FIELDS}`

export const REGISTER = gql`
mutation register(
  $identity: String! 
  $display: String! 
  $email: String! 
  $password: String!
  $captcha: String!
  $code: String!
){
  session: userRegister(
    identity: $identity
    display: $display
    email: $email
    password: $password
    captchaId: $captcha
    captchaCode: $code
  ){ ...Me }
}
${USER_FIELDS}`

export const PROFILE = gql`
mutation profile(
  $identity:    String!
  $password:    String!
  $authCode:    String
  $display:     String! 
  $email:       String! 
  $newPassword: String
  $totpCode:    String
  $totpSeed:    String
 ){
  session: userUpdate(
    identity:    $identity
    password:    $password
    authCode:    $authCode
    display:     $display
    email:       $email
    newPassword: $newPassword
    totpSeed:    $totpSeed
    totpCode:    $totpCode
  ){ identity display active email }
}`

export const FORGOT = gql`
mutation recover(
  $identity: String!
  $captcha: String!
  $code:    String!
){
  message: userRecoverStart(
    identity:    $identity
    captchaId:   $captcha
    captchaCode: $code
  )
}`

export const RECOVER = gql`
mutation recover($identity: String! $token: String! $password: String!) {
  session: userRecoverComplete(
    identity: $identity
    token: $token
    password: $password
  ){ ...Me }
}
${USER_FIELDS}`

export const LOGIN = gql`
mutation login($user: String! $pass: String! $totpCode: String){
    session: createSession(identity: $user password: $pass totpCode: $totpCode)
    { ...Me }
}
${USER_FIELDS}`

export const LOGOUT = gql`
mutation { 
    session: setSession(session:"")
    { ...Me }
}
${USER_FIELDS}`

export const updateUser = name => (cache, {data: { session }}) => {
    if (!session.active)
      sessionStorage.removeItem(name);
    else
      sessionStorage.setItem(name, session.session);
  
    cache.writeQuery({
      query: USER,
      data: {session},
    });
  }
  
export const setSession = (client, name) => {
    const session = sessionStorage.getItem(name);

    session && client.mutate({
      mutation: SET_SESSION,
      variables: { session },
      update: updateUser(name)
    })
}
