import React from 'react'
import PropTypes from 'prop-types';

import { Subscription, Mutation } from 'react-apollo'
import { Fragment } from 'react'
import gql from 'graphql-tag';
import { uuid } from 'components/utils'

const UUID = uuid();

const GAME_CLOCK = gql`
  subscription doClock($eventID: ID!){
    clock(id: $eventID ) {
      id time status
      startTime stopTime totalStop
      stoppage{ reason duration }
    }
  }
`
const CLOCK_RESET = gql`
  mutation start($eventID: ID!){
    initClock(id: $eventID)
  }
`
const CLOCK_START = gql`
  mutation start($eventID: ID!){
    startClock(id: $eventID)
  }
`
const CLOCK_TECH_STOP = gql`  
  mutation techStop($eventID: ID!){
    stopClock(id: $eventID reason: TECH_STOP)
  }
`
const CLOCK_GAME_STOP = gql`  
  mutation gameStop($eventID: ID!){
    stopClock(id: $eventID reason: GAME_STOP)
  }
`

export const GameClock = ({ id }) => (
  <Subscription
    shouldResubscribe
    subscription={GAME_CLOCK}
    variables={{ eventID: id }}
    onSubscriptionData={
      ({client, subscriptionData: { data: { clock: { status, startTime, stopTime, time } } }}) => status !== 'GAME_END' && client.mutate({
        mutation: gql`mutation push($payload: String!){mqttPublish(topic: "phoenix/clock", message: $payload)}`,
        variables: {
          payload: JSON.stringify({ uuid: UUID, status, startTime, stopTime, time }),
        },
      })
    }
  >
    {({data, error, loading}) => {
      if (loading) return <div> Loading... </div>
      if (error) return <div> Error :( <pre> {id} {JSON.stringify(error)} </pre></div>

      return ( <Clock { ...data.clock } /> )
    }}
  </Subscription>
);
GameClock.propTypes = {
  eventID: PropTypes.number,
}

const Clock = ({ id, startTime, stopTime, totalStop, status, stoppage }) => (
  <Fragment>
    <h1>{startTime}</h1>
    <h3>{status}</h3>
    <h2>{stopTime}</h2>
    <dl>
      {stoppage.sort((a, b) => a.reason.localeCompare(b.reason)).map(s => (
        <Fragment>
          <dt key={s.reason}>{s.reason}</dt>
          <dd key={s.reason + '-duration'}>{s.duration}</dd>
        </Fragment>  
      ))}
      <dt key='total-stop'>TOTAL_STOP</dt>
      <dd key='total-duration'>{totalStop}</dd>
    </dl>
    <div>
      <Mutation mutation={CLOCK_START}>
        {(start) => (<p onClick={()=>start({ variables:{ eventID: id } })}>Start</p>)}
      </Mutation>
      
      <Mutation mutation={CLOCK_GAME_STOP}>
        {(stop) => (<p onClick={() => stop({ variables:{ eventID: id } })}>Game Stop</p>)}
      </Mutation>

      <Mutation mutation={CLOCK_TECH_STOP}>
        {(stop) => (<p onClick={() => stop({ variables:{ eventID: id } })}>Tech Stop</p>)}
      </Mutation>

      <Mutation mutation={CLOCK_RESET}>
        {(reset) => (<p onClick={() => reset({ variables:{ eventID: id } })}>Reset</p>)}
      </Mutation>
    </div>
  </Fragment>
)
Clock.propTypes = {
  id: PropTypes.number,
  startTime: PropTypes.string,
  stopTime: PropTypes.string,
  totalStop: PropTypes.string,
  status: PropTypes.string,
  stoppage: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string,
      duration: PropTypes.string,
    })
  ),
}
