import React from 'react'
import './footer.css'

import { Query } from "@apollo/react-components";
import gql from 'graphql-tag';


export const Footer = () => (
  <Query query={gql`query {appInfo{name version build}}`}>
    {
      ({loading, error, data:{ appInfo }}) => {
        if (loading) return "loading...";
        if (error) return "Error :(";

        const {name, version, build } = appInfo;
        return (
          <footer>
            <span className="left text-muted">{name}</span>
            <span className="right text-muted">{version} ({build})</span>
          </footer>
        )
      }
    }
  </Query>
)

