import ApolloClient from "apollo-client";
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { ApolloLink } from 'apollo-link';
import { emitError } from 'components/utils';

const wsProto = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
const wsHost = window.location.host;
const wsPath = '/v1/graphql';
const wsURI = process.env.REACT_APP_URI || `${wsProto}//${wsHost}${wsPath}`;

const wsLink = new SubscriptionClient(
  wsURI,
  { reconnect: true }
);
const errLink = onError(emitError);
const link = ApolloLink.from([
  errLink,
  wsLink,
]);

export const client = new ApolloClient({
  link,
  fetchOptions: {
    mode: 'cors',
    credentials: 'same-origin'
  },
  cache: new InMemoryCache(),
})
