import React from 'react'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import gql from 'graphql-tag';

import {
    SplitPane,
    GameClock,
    Splash,
} from 'components'

const EVENTS = gql `{
  event { id name date }
}`

export const List = ({ id: activeID }) => (
  <Query query={EVENTS}>
    {({ loading, error, data }) => {
      if (loading) return <p> Loading... </p>
      if (error) return <p> Error :( </p>
      const { event } = data
      activeID = parseInt(activeID, 10)

      return (
        <div className='event-list list-group'>
            {event.map(({ id, name, date }) => (
              <Link key={id} className={`list-group-item ${activeID === id ? 'active' : ''}`} to={`/events/${id}`}>
                <p><strong>{name}</strong> </p>
                <small>Date: {new Date(date).toLocaleDateString()} </small>
              </Link>
            ))}
        </div>
      )
    }}
  </Query>
)

export const Events = ({ match: { params } }) => (
  <SplitPane 
    left={<List { ...params }/>}
    right={<div> {params.id ? <GameClock { ...params }/> : <Splash />} </div>}
  />
)

Events.menu = [{ name: 'Events', link: '/events' }]
Events.routes = [
  { path: '/events/:id', component: Events },
  { path: '/events', component: Events },
]