import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import {
  Route,
  Switch,
  BrowserRouter,
} from 'react-router-dom';
import {
  ErrorBoundary,
  Framework,
  User,
  Splash,
} from 'components'
import { menu, routes } from './module'
import { client } from 'utils/client'

const nav = {
  title: 'Phoenix CM',
  menu,
  routes: [
    ...User.routes,
    ...routes,
    { route: '/', component: Splash },
  ]
}

export const App = () => (
  <ApolloProvider client={client}>
    <User.Context>
      <BrowserRouter>
        <div className="BackSplash"><header /></div>
        
        <div>
          <Framework nav={nav} user={User.Menu} overlay={<Splash />}>
            <ErrorBoundary>
              <Switch>
                {nav.routes.map((props, i) => <Route key={i} {...props} />)}
              </Switch>
            </ErrorBoundary>
          </Framework>
        </div>
        
      </BrowserRouter>
    </User.Context>
  </ApolloProvider>
);

export default App;